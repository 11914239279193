<template>
  <div>
    <a-form :form="form" style="width: 60%; margin: 0 auto;">
      <a-row :gutter="[16,16]">
        <a-col :span="10" :offset="5">
          <a-form-item label="仓库" >
            <a-select
              disabled
              option-label-prop="tt"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              show-search
              allow-clear
              v-decorator="[
                'wms_warehouse_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]"
              placeholder="仓库名称"
              :default-active-first-option="false"
              @search="handleWmsWarehouseSearch"
              @change="handleWmsWarehouseChange"
            >
              <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :tt="d.name">
                {{ d.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="库位">
            <a-select
              disabled
              option-label-prop="tt"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              show-search
              allow-clear
              v-decorator="[
                'wms_cargo_space_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]"
              placeholder="库位名称"
              :default-active-first-option="false"
              @search="handleWmsCargoSpaceSearch"
              @change="handleWmsCargoSpaceChange"
            >
              <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.id" :tt="d.name">
                {{ d.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="货品">
            <a-select
              disabled
              option-label-prop="tt"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              show-search
              allow-clear
              v-decorator="[
                'wms_goods_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]"
              placeholder="货品名称"
              :default-active-first-option="false"
              @search="handleWmsGoodsSearch"
              @change="handleWmsGoodsChange"
            >
              <a-select-option v-for="d in dataSource_wms_goods" :key="d.id" :tt="d.name">
                {{ d.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="商品" v-if="false">
            <a-select
              disabled
              option-label-prop="tt"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              show-search
              allow-clear
              v-decorator="[
                'wms_commodity_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]"
              placeholder="货品名称"
              :default-active-first-option="false"
              @search="handleWmsGoodsSearch"
              @change="handleWmsGoodsChange"
            >
              <a-select-option v-for="d in dataSource_wms_commodity" :key="d.id" :tt="d.batch">
                {{ d.batch }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="数量">
            <a-input v-decorator="['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]" disabled />
          </a-form-item>
          <a-form-item label="破损数量" >
            <a-input v-decorator="['broken_count', {rules: [{required: false, message: '必填项，请填写信息'}]}]" disabled />
          </a-form-item>
          <a-form-item label="状态" >
            <a-select
              v-decorator="[
                'status',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
            >
              <a-select-option :value="key" v-for="(val, key) of commodity_purchase_status" :key="key">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
        style="width: 60%; margin: 0 auto"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { commodity_purchase_update } from '@/api/c_wms_commodity_purchase'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import { goods_list } from '@/api/c_wms_goods'
import { commodity_list } from '@/api/c_wms_commodity'
// 表单字段
const fields = ['id', 'wms_warehouse_id', 'wms_cargo_space_id', 'wms_goods_id', 'wms_commodity_id', 'count', 'broken_count', 'status']
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      location: '',
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      dataSource_wms_commodity: [],
      dataSource_wms_goods: [],
      commodity_purchase_status: []
    }
  },
  mounted () {
    this.commodity_purchase_status = this.$Dictionaries.commodity_purchase_status
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        console.log('--------------------', values)
        commodity_purchase_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
    })
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        this.id = formData.id
        console.log(formData)
        formData.status = data.status.toString()
        form.setFieldsValue(formData)
      })
      this.handleWmsWarehouseSearch(data.wms_warehouse)
      this.handleWmsCargoSpaceSearch(data.wms_cargo_space)
      this.handleWmsGoodsSearch(data.wms_goods)
      this.handleWmsGoodsChange(data.wms_goods_id)
    },
    handleWmsWarehouseSearch (value) {
      warehouse_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_wms_warehouse = !value ? [] : result
      })
    },
    handleWmsWarehouseChange (value) {
      console.log(value)
      let objneo = this.dataSource_wms_warehouse.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_warehouse)
      var obj = Object.assign({}, objneo)
      console.log(obj)
    },
    handleWmsCargoSpaceSearch (value) {
      cargo_space_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_wms_cargo_space = !value ? [] : result
      })
    },
    handleWmsCargoSpaceChange (value) {
      console.log(value)
    },
    handleWmsGoodsSearch (value) {
      goods_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_wms_goods = !value ? [] : result
      })
    },
    handleWmsGoodsChange (value) {
      let objneo = this.dataSource_wms_goods.find((item) => {
        return item.id === value
      })
      const obj = Object.assign({}, objneo)
      this.getCommodity(obj.id)
    },
    getCommodity (id) {
      const requestBody = { wms_goods_id: id }
      commodity_list(requestBody).then(res => {
        this.dataSource_wms_commodity = res.data.entries
      })
    }

  }
}
</script>
