<template>
  <a-modal
    title="入库"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="仓库">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'wms_warehouse_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="仓库名称"
                @search="handleWmsWarehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="库位">
              <a-select
                option-label-prop="tt"
                :show-arrow="true"
                :filter-option="false"
                :not-found-content="null"
                show-search
                v-decorator="[
                  'wms_cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                :default-active-first-option="false"
                @change="handleWmsCargoSpaceChange"
              >
                <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="可用数量">
              <a-input-number :min="0" v-decorator="['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" style="width: 100%"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="破损数量" >
              <a-input-number :min="0" v-decorator="['broken_count', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]" style="width: 100%" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { commodity_list } from '@/api/c_wms_commodity'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import { goods_list } from '@/api/c_wms_goods'

// 表单字段
const fields = ['id', 'batch', 'count', 'wms_goods_id', 'wms_goods']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      wms_commodity_purchases: [],
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      dataSource_wms_goods: [],
      dataSource_wms_commodity: []
    }
  },
  created () {
    this.handleWmsWarehouseSearch('')
    // this.handleWmsCargoSpaceSearch('')
    this.handleWmsGoodsSearch('')
    // 防止表单未注册
    setTimeout(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      // // 当 model 发生改变时，为表单设置值
      this.model && this.form.setFieldsValue(pick(this.model, fields))
      this.$watch('model', () => {
        this.model && this.form.setFieldsValue(pick(this.model, fields))
      })
    })
  },
  mounted () {
    this.wmsWarehouseList()
    // fields.forEach(v => this.form.getFieldDecorator(v))
    // // 当 model 发生改变时，为表单设置值
    // // this.model.count = this.model.inbound_count
    // // this.model.wms_goods_id = this.model.id
    // // this.model.wms_goods = this.model.name
    // this.$watch('model', () => {
    //   this.model && this.form.setFieldsValue(pick(this.model, fields))
    // })
    // this.getCommodity(this.model.id)
  },
  methods: {
    filterOption (input, option) {
      // console.log(input, option.componentOptions.children[0].text, this.dataSource_wms_warehouse.length + '-------------------------')
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    wmsWarehouseList () {
    },
    handleWmsWarehouseSearch (value) {
      warehouse_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      })
    },
    handleWmsWarehouseChange (value) {
      console.log(value)
      let objneo = this.dataSource_wms_warehouse.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_warehouse)
      var obj = Object.assign({}, objneo)
      console.log(obj)
      this.dataSource_wms_cargo_space = undefined
      this.handleWmsCargoSpaceSearch2(value)
    },
    handleWmsCargoSpaceSearch (value) {
      console.log('-------------', value)
      if (value) {
        cargo_space_list({ name: value }).then((res) => {
          let result = res.data.entries || []
          this.dataSource_wms_cargo_space = result
        })
      }
    },
    handleWmsCargoSpaceSearch2 (value) {
      cargo_space_list({ wms_warehouse_id: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_wms_cargo_space = result
      })
    },
    handleWmsCargoSpaceChange (value) {
      console.log(value)
      let objneo = this.dataSource_wms_cargo_space.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_cargo_space)
      var obj = Object.assign({}, objneo)
      console.log(obj)
    },
    handleWmsGoodsSearch (value) {
      goods_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_wms_goods = result
      })
    },
    handleWmsGoodsChange (value) {
      console.log(value)
      let objneo = this.dataSource_wms_goods.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_goods)
      const obj = Object.assign({}, objneo)
      console.log(obj)
      this.getCommodity(obj.id)
    },
    getCommodity (id) {
      const requestBody = { wms_goods_id: id }
      commodity_list(requestBody).then(res => {
        console.log(res)
        this.dataSource_wms_commodity = res.data.entries
      })
    }
  }
}
</script>
