import request from '@/utils/request'
const requestApi = {
  wms_commodity_purchase_list: '/wms/commodity_purchase/',
  wms_commodity_purchase_create: '/wms/commodity_purchase/',
  wms_commodity_purchase_update: '/wms/commodity_purchase/',
  wms_commodity_purchase_delete: '/wms/commodity_purchase/'
}

export function commodity_purchase_list (query) {
  return request({
    url: requestApi.wms_commodity_purchase_list,
    method: 'get',
    params: query
  })
}

export function commodity_purchase_create (data) {
  return request({
    url: requestApi.wms_commodity_purchase_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function commodity_purchase_update (data, commodity_purchase_id) {
  return request({
    url: requestApi.wms_commodity_purchase_update + commodity_purchase_id + '/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}
export function commodity_purchase_delete (commodity_purchase_id) {
  return request({
    url: requestApi.wms_commodity_purchase_delete + commodity_purchase_id + '/',
    method: 'delete'
  })
}
