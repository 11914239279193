<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="仓库">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.wms_warehouse_name"
                placeholder="仓库名称"
                :default-active-first-option="false"
                @search="handleWmsWarehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.name" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="库位">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.wms_cargo_space_name"
                v-decorator="[
                  'wms_cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                :default-active-first-option="false"
                @search="handleWmsCargoSpaceSearch"
                @change="handleWmsCargoSpaceChange"
              >
                <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.name" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="货品">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.wms_goods_name"
                placeholder="货品名称"
                :default-active-first-option="false"
                @search="handleWmsGoodsSearch"
                @change="handleWmsGoodsChange"
              >
                <a-select-option v-for="d in dataSource_wms_goods" :key="d.name" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="批次">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.wms_commodity_batch"
                placeholder="批次"
                :default-active-first-option="false"
                @search="handleWmsCommoditySearch"
                @change="handleWmsCommodityChange"
              >
                <a-select-option v-for="d in dataSource_wms_commodity" :key="d.batch" :tt="d.batch">
                  {{ d.batch }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" allow-clear>
                <a-select-option
                  :value="key"
                  v-for="(val, key) of this.$Dictionaries.commodity_purchase_status"
                  :key="key">{{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 100px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-if="record.status === 1">入库</a>
          <a-divider type="vertical" v-if="record.status === 1" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
            v-if="record.status !== 2"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <commodity-purchase-form
      ref="createCommodityPurchaseModal"
      v-if="purchase_form_visible"
      :visible="purchase_form_visible"
      :loading="confirmLoading"
      :model="purchase_form_mdl"
      @cancel="handleInCancel"
      @ok="handleInOk" />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '@/views/c-wms/commodity_purchase/modules/CreateForm'
import CommodityPurchaseForm from '@/views/c-wms/commodity_purchase/modules/CommodityPurchaseForm'
import {
  commodity_purchase_create,
  commodity_purchase_delete,
  commodity_purchase_list,
  commodity_purchase_update
} from '@/api/c_wms_commodity_purchase'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import { goods_list } from '@/api/c_wms_goods'
import { commodity_list } from '@/api/c_wms_commodity'
import { Base64 } from 'js-base64'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    CommodityPurchaseForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      purchase_form_visible: false,
      purchase_form_mdl: null,
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      dataSource_wms_commodity: [],
      dataSource_wms_goods: [],
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '仓库',
          dataIndex: 'wms_warehouse'
        },
        {
          title: '库位',
          align: 'center',
          dataIndex: 'wms_cargo_space',
          ellipsis: true
        },
        {
          title: '货品',
          align: 'center',
          dataIndex: 'wms_goods'
        },
        {
          title: '批次',
          align: 'center',
          dataIndex: 'batch'
        },
        {
          title: '可用数量',
          align: 'center',
          dataIndex: 'count',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '破损数量',
          align: 'center',
          dataIndex: 'broken_count',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.commodity_purchase_status[text] || '无'
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return commodity_purchase_list(Object.assign(parameter, this.queryParam, { commodity_id: this.$route.params.commodity_id }))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.purchase_form_mdl = null
    console.log(this.$route.query.record)
    if (this.$route.query.record !== undefined) {
      this.purchase_form_mdl = JSON.parse(Base64.decode(this.$route.query.record))
    } else {
      this.purchase_form_mdl = null
    }
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      if (this.$route.params.commodity_id) {
        this.purchase_form_visible = true
      } else {
        this.mdl = null
        this.visible = true
      }
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      commodity_purchase_update({ status: 2 }, record.id)
        .then((res) => {
          if (res.code === 1000) {
            this.$refs.table.refresh(true)
          }
          console.log(res)
        })
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      console.log(record.id)
      commodity_purchase_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          if (values.broken_count === null) {
            values.broken_count = 0
          }
          commodity_purchase_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleInCancel () {
      this.purchase_form_visible = false
      const form = this.$refs.createCommodityPurchaseModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleInOk () {
      const form = this.$refs.createCommodityPurchaseModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          if (values.broken_count === null) {
            values.broken_count = 0
          }
          values.wms_goods_id = this.purchase_form_mdl.wms_goods_id
          values.wms_commodity_id = this.purchase_form_mdl.id
          values.batch = this.purchase_form_mdl.batch
          values.wms_goods = this.purchase_form_mdl.wms_goods
          commodity_purchase_create(values).then(res => {
            this.purchase_form_visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleWmsWarehouseSearch (value) {
      warehouse_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_wms_warehouse = !value ? [] : result
      })
    },
    handleWmsWarehouseChange (value) {
      console.log(value)
      let objneo = this.dataSource_wms_warehouse.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_warehouse)
      var obj = Object.assign({}, objneo)
      console.log(obj)
    },
    handleWmsCargoSpaceSearch (value) {
      cargo_space_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_wms_cargo_space = !value ? [] : result
      })
    },
    handleWmsCargoSpaceChange (value) {
      console.log(value)
    },
    handleWmsGoodsSearch (value) {
      goods_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_wms_goods = !value ? [] : result
      })
    },
    handleWmsGoodsChange (value) {
      let objneo = this.dataSource_wms_goods.find((item) => {
        return item.id === value
      })
      const obj = Object.assign({}, objneo)
      console.log(obj)
    },
    handleWmsCommoditySearch (value) {
      commodity_list({ batch: value }).then(res => {
        console.log(res)
        let result = res.data.entries || []
        this.dataSource_wms_commodity = !value ? [] : result
      })
    },
    handleWmsCommodityChange (value) {
      let objneo = this.dataSource_wms_commodity.find((item) => {
        return item.id === value
      })
      const obj = Object.assign({}, objneo)
      console.log(obj)
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
