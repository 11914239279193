var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading,"maskClosable":false},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"仓库"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'wms_warehouse_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'wms_warehouse_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"仓库名称"},on:{"search":_vm.handleWmsWarehouseSearch,"change":_vm.handleWmsWarehouseChange}},_vm._l((_vm.dataSource_wms_warehouse),function(d){return _c('a-select-option',{key:d.id,attrs:{"value":d.id}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"库位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'wms_cargo_space_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'wms_cargo_space_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":true,"filter-option":false,"not-found-content":null,"show-search":"","placeholder":"库位名称","default-active-first-option":false},on:{"change":_vm.handleWmsCargoSpaceChange}},_vm._l((_vm.dataSource_wms_cargo_space),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"货品"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'wms_goods_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'wms_goods_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":true,"filter-option":false,"not-found-content":null,"show-search":"","placeholder":"货品名称","default-active-first-option":false},on:{"search":_vm.handleWmsGoodsSearch,"change":_vm.handleWmsGoodsChange}},_vm._l((_vm.dataSource_wms_goods),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"批次"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'wms_commodity_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'wms_commodity_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":true,"filter-option":false,"not-found-content":null,"placeholder":"批次","default-active-first-option":false}},_vm._l((_vm.dataSource_wms_commodity),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.batch}},[_vm._v(" "+_vm._s(d.batch)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"可用数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"100%"},attrs:{"min":0}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"破损数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['broken_count', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]),expression:"['broken_count', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"100%"},attrs:{"min":0}})],1),(false)?_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [
                    { required: false, message: '请选择' },
                  ],
                },
              ]),expression:"[\n                'status',\n                {\n                  rules: [\n                    { required: false, message: '请选择' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"选择"}},_vm._l((this.$Dictionaries.commodity_purchase_status),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(_vm._s(val))])}),1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }